import { useQuery } from '@apollo/client'
import { EuiSpacer } from '@elastic/eui'
import { TradeSubmitWorkflowStepDetailInput, TradeWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { SearchTradesDocument } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface TradeWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: TradeWorkflowStepDetailFragment
  result: Partial<TradeSubmitWorkflowStepDetailInput>
  onChange: (result: TradeSubmitWorkflowStepDetailInput) => void
}

export const TradeWorkflowV2Step = ({ result, onChange, detail }: TradeWorkflowV2StepProps) => {
  const { data, loading } = useQuery(SearchTradesDocument, {
    variables: {
      input: {
        size: 200
      }
    }
  })

  const trades = data?.searchTrades.results ?? []

  const tradeId = result.tradeId

  const tradeOptions: ListNavOption[] = trades.map((trade) => ({
    value: trade.id,
    label: trade.description ?? trade.name,
    icon: trade.iconUrl
  }))
  const selectedTrade = tradeOptions.find((o) => o.value === tradeId)

  return (
    <>
      <Callout type={'script'}>{detail.question}</Callout>
      <EuiSpacer size="s" />
      <ListNav
        loading={loading && !trades.length}
        value={selectedTrade}
        options={tradeOptions}
        onChange={(trade) => onChange({ tradeId: trade.value })}
      />
    </>
  )
}
