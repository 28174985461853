import { useApolloClient, useMutation } from '@apollo/client'
import { WorkflowFragment, WorkflowNextStepFragment, WorkflowStepChoiceFragmentDoc } from '@fallonsolutions/types'
import {
  GetWorkflowDocument,
  GetWorkflowQuery,
  ResetWorkflowDocument,
  ReverseWorkflowStepDocument,
  SubmitWorkflowStepDocument,
  SubmitWorkflowStepInput,
  WorkflowFragmentDoc,
  WorkflowStepFragmentDoc
} from '../api/generated-types'
import { DetailContainer } from '../common/detail-container'
import { WorkflowV2View } from './workflow-v2-view'

export interface WorkflowV2ContainerProps {
  workflowId: string
  onSubmit?: (response?: WorkflowFragment) => void
}

export const WorkflowV2Container = ({ workflowId, onSubmit }: WorkflowV2ContainerProps) => {
  const client = useApolloClient()

  const [submitWorkflowStep, { data: submitData, loading: submitLoading }] = useMutation(SubmitWorkflowStepDocument)
  const [reverseWorkflowStep, { data: reverseData, loading: reverseLoading }] = useMutation(ReverseWorkflowStepDocument)
  const [resetWorkflowStep, { data: resetData, loading: resetLoading }] = useMutation(ResetWorkflowDocument)

  const loading = submitLoading || reverseLoading || resetLoading || false

  const onNext = (input: SubmitWorkflowStepInput, nextStep?: WorkflowNextStepFragment) => {
    console.log('workflow: nextStep')
    submitWorkflowStep({
      variables: { input },
      optimisticResponse: (vars, { IGNORE }) => {
        console.log('optimisticResponse: nextStep', nextStep)
        const workflow = client.readFragment({
          id: `Workflow:${vars.input.workflowId}`,
          fragment: WorkflowFragmentDoc,
          fragmentName: 'Workflow'
        })
        console.log('optimisticResponse: step fragment', `WorkflowStep:${vars.input.stepId}`)
        const step = client.readFragment({
          id: `WorkflowStep:${vars.input.stepId}`,
          fragment: WorkflowStepFragmentDoc,
          fragmentName: 'WorkflowStep'
        })
        console.log(
          'optimisticResponse: choice fragment',
          `WorkflowStepChoice:${vars.input.detail.singleChoice?.choiceId}`
        )
        const choice = vars.input.detail.singleChoice?.choiceId
          ? client.readFragment({
              id: `WorkflowStepChoice:${vars.input.detail.singleChoice.choiceId}`,
              fragment: WorkflowStepChoiceFragmentDoc,
              fragmentName: 'WorkflowStepChoice'
            })
          : undefined
        if (!workflow || !nextStep || !step) {
          console.log('optimisticResponse: missing workflow, step or next step')
          if (!workflow) console.log('optimisticResponse: no workflow')
          if (!nextStep) console.log('optimisticResponse: no nextStep')
          if (!step) console.log('optimisticResponse: no step')
          return IGNORE as any
        }
        const newCurrentStep = {
          ...workflow.currentStep,
          step: nextStep.step,
          nextSteps: []
        }
        const newStepResult = {
          __typename: 'WorkflowStepResult',
          stepId: input.stepId,
          step,
          choice,
          value: null
        }
        console.log('optimisticResponse: newCurrentStep', newCurrentStep)
        return {
          submitWorkflowStep: {
            __typename: 'SubmitWorkflowStepPayload',
            workflow: {
              ...workflow,
              steps: [...workflow.steps, newStepResult],
              currentStep: newCurrentStep
            }
          }
        }
      }
    })
    onSubmit && submitData && onSubmit(submitData.submitWorkflowStep?.workflow ?? undefined)
  }

  const onBack = () => {
    console.log('workflow: prevStep')
    reverseWorkflowStep({
      variables: { input: { workflowId } },
      optimisticResponse: (vars, { IGNORE }) => {
        const workflow = client.readFragment({
          id: `Workflow:${vars.input.workflowId}`,
          fragment: WorkflowFragmentDoc,
          fragmentName: 'Workflow'
        })
        if (!workflow) {
          console.log('optimisticResponse: no workflow')
          return IGNORE as any
        }
        console.log('optimisticResponse: workflow steps', workflow.steps.length)
        const prevStepId = workflow.steps[workflow.steps.length - 1]?.step.id
        const prevStep = client.readFragment({
          id: `WorkflowStep:${prevStepId}`,
          fragment: WorkflowStepFragmentDoc,
          fragmentName: 'WorkflowStep'
        })
        if (!prevStep || !workflow.currentStep) {
          console.log('optimisticResponse: no prevStep')
          return IGNORE as any
        }
        console.log('optimisticResponse: prevStep', prevStep)
        const newCurrentStep = {
          ...workflow.currentStep,
          step: prevStep,
          nextSteps: []
        }
        return {
          reverseWorkflowStep: {
            __typename: 'ReverseWorkflowStepPayload',
            workflow: {
              ...workflow,
              currentStep: newCurrentStep,
              steps: workflow.steps.slice(0, -1)
            }
          }
        }
      }
    })

    onSubmit && reverseData && onSubmit(reverseData.reverseWorkflowStep?.workflow ?? undefined)
  }

  const onReset = () => {
    console.log('workflow: reset')
    resetWorkflowStep({
      variables: { input: { workflowId } }
    })
    onSubmit && resetData && onSubmit(resetData.resetWorkflow?.workflow ?? undefined)
  }

  const view = (data: GetWorkflowQuery) => {
    const workflow = data.getWorkflow?.workflow
    return workflow ? (
      <WorkflowV2View loading={loading} workflow={workflow} onNext={onNext} onBack={onBack} onReset={onReset} />
    ) : (
      <></>
    )
  }

  return (
    <DetailContainer
      variables={{ input: { workflowId } }}
      view={view}
      parentPath={'/workflow-v2'}
      documentNode={GetWorkflowDocument}
      modelName="workflow"
      modelNamePlural="workflows"
      getTitle={(data) => data?.getWorkflow?.workflow?.reference ?? 'loading'}
      showBreadcrumbs={true}
    />
  )
}

export default WorkflowV2Container
