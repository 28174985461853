import { isNil } from 'lodash-es'
import { MoneyUtils } from '@fallonsolutions/money'
import { formatMoney } from './money'
import { timeFormatter } from '../common/time'
import createPersistedState from '../use-persisted-state'
import '../static/css/metrics.css'

const useAbbreviatedMetrics = createPersistedState<boolean>('metrics-abbreviated')

const emptyValue = '-'

export enum MetricType {
  Number = 'number',
  Currency = 'currency',
  Time = 'time',
  Distance = 'distance',
  Percentage = 'percentage'
}

export interface IMetric {
  label: string
  value: number
  type?: MetricType // default to MetricType.Number
}

export interface MetricProps {
  primary: IMetric
  secondary?: IMetric
  compressed?: boolean
}

export const Metric = (props: MetricProps) => {
  const [abbreviatedMetrics] = useAbbreviatedMetrics(true)
  const { primary, secondary, compressed } = props
  return (
    <div className={`metrics ${compressed ? 'metrics--compressed' : ''}`}>
      <div className="metric metric--primary">
        <div className="metric__label metric__label--primary">{primary.label}</div>
        <div className="metric__value metric__value--primary" title={detailMetricValue(primary.value, primary.type)}>
          {abbreviatedMetrics
            ? displayMetricValue(primary.value, primary.type)
            : detailMetricValue(primary.value, primary.type)}
        </div>
      </div>
      {!isNil(secondary) && (
        <div className="metric metric--secondary">
          <span className="metric__label metric__label--secondary">{secondary.label}</span>
          <span
            className="metric__value metric__value--secondary"
            title={detailMetricValue(secondary.value, secondary.type)}
          >
            {abbreviatedMetrics
              ? displayMetricValue(secondary.value, secondary.type)
              : detailMetricValue(secondary.value, secondary.type)}
          </span>
        </div>
      )}
    </div>
  )
}

export const displayMetricValue = (value?: number, type: MetricType = MetricType.Number): string => {
  switch (type) {
    case MetricType.Currency:
      return formatCurrencyShort(value)
    case MetricType.Time:
      return timeFormatter(value)
    case MetricType.Distance:
      return distanceFormatter(value)
    case MetricType.Percentage:
      return percentageFormatter(value)
    case MetricType.Number:
    default:
      return formatIntegerShort(value)
  }
}

export const detailMetricValue = (value?: number, type: MetricType = MetricType.Number): string => {
  switch (type) {
    case MetricType.Currency:
      return formatCurrency(value)
    case MetricType.Time:
      return timeFormatter(value)
    case MetricType.Distance:
      return distanceFormatter(value)
    case MetricType.Percentage:
      return percentageFormatter(value, 1)
    case MetricType.Number:
    default:
      return formatInteger(value)
  }
}

export const formatIntegerShort = (value?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(value > 10000000 ? 0 : 2) + 'm'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(value > 5000 ? 0 : 1) + 'k'
  } else {
    return value.toFixed(0)
  }
}

export const formatCurrencyShort = (value?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  if (value >= 1000) {
    return '$' + formatIntegerShort(value)
  } else {
    return formatMoney(MoneyUtils.fromNumber(value), { format: '$0,0' })
  }
}

export const distanceFormatter = (value?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  return value.toFixed(value > 9 || value <= 0 ? 0 : 1) + 'km'
}

export const percentageFormatter = (value?: number, precision?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  return value.toFixed(precision ?? 0) + '%'
}

export const formatCurrency = (value?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  return value.toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const formatInteger = (value?: number): string => {
  if (isNil(value)) {
    return emptyValue
  }
  return value.toLocaleString('en-AU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
}
