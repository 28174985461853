import { makeHumanReadable } from '@falloncore/util'
import { JobType, TradeType, WorkflowFragment } from '@fallonsolutions/types'
import { JobClassificationActionResult } from '../actions/action-job-classification-types'
import { ServiceType, TopicActionResult } from '../topics/action-topic-model'

//todo: plugin the reverse mapping
//appointment requirements leave? target, via new types, against techs/schedule groups

const getV1 = (workflow: WorkflowFragment) => {
  const { state } = workflow
  return {
    serviceType: ServiceType.Installation,
    trade: TradeType.Data,
    type: JobType.Service,
    category: 'General',
    jobDescription: state.outcome.notes,
    workRequiredNotes:
      state.outcome.notes + '\n\n' + state.outcome?.steps?.map((s) => s.step.name + ': ' + s.choice?.label).join('\n\n')
  }
}

export const enquiryBridge = (workflow?: WorkflowFragment): JobClassificationActionResult | undefined => {
  if (!workflow) {
    return undefined
  }
  const { state } = workflow
  const customerType = state.outcome.customerType
  const jobDescription = state.outcome.notes

  const { trade, category, type } = getV1(workflow)
  const actionCompleted = workflow.currentStep?.nextSteps.length === 0
  const value =
    actionCompleted === true ? `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${type}` : undefined
  const travelFee = state.outcome.travelFee
    ? {
        label: state.outcome.travelFee?.name,
        amount: state.outcome.travelFee.amount.amount
      }
    : undefined

  const topic: TopicActionResult = {
    trade,
    category,
    type,
    jobDescription,
    value: workflow.state.outcome.topic?.name,
    actionCompleted
  }
  return { category, topic, customerType, trade, type, travelFee, actionCompleted, value }
}
