import {
  CallOutWorkflowStepDetailFragment,
  CustomerTypeWorkflowStepDetailFragment,
  MultipleChoiceWorkflowStepDetailFragment,
  ServiceTypeWorkflowStepDetailFragment,
  SingleChoiceWorkflowStepDetailFragment,
  SubmitWorkflowStepDetailInput,
  SubtopicWorkflowStepDetailFragment,
  TextWorkflowStepDetailFragment,
  TopicWorkflowStepDetailFragment,
  TradeWorkflowStepDetailFragment,
  WorkflowInputFragment,
  WorkflowStepFragment,
  WorkflowStepType
} from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { CallOutWorkflowV2Step } from './call-out-workflow-v2-step'
import { CustomerTypeWorkflowV2Step } from './customer-type-workflow-v2-step'
import { MultipleChoiceWorkflowV2Step } from './multiple-choice-workflow-v2-step'
import { ServiceTypeWorkflowV2Step } from './service-type-workflow-v2-step'
import { SingleChoiceWorkflowV2Step } from './single-choice-workflow-v2-step'
import { SubtopicWorkflowV2Step } from './subtopic-workflow-v2-step'
import { TextWorkflowV2Step } from './text-workflow-v2-step'
import { TopicWorkflowV2Step } from './topic-workflow-v2-step'
import { TradeWorkflowV2Step } from './trade-workflow-v2-step'

export interface WorkflowStepViewProps {
  input: WorkflowInputFragment
  step: WorkflowStepFragment
  result: SubmitWorkflowStepDetailInput
  onChange: (result: SubmitWorkflowStepDetailInput) => void
}

export const WorkflowV2StepView = ({ input, step, result, onChange }: WorkflowStepViewProps) => {
  const detailView = getDetailView({ input, step, result, onChange })
  return detailView
}

interface DetailViewProps {
  input: WorkflowInputFragment
  step: WorkflowStepFragment
  result: SubmitWorkflowStepDetailInput
  onChange: (result: SubmitWorkflowStepDetailInput) => void
}

const getDetailView = ({ input, step, result, onChange }: DetailViewProps) => {
  switch (step.type) {
    case WorkflowStepType.SingleChoice:
      return (
        <SingleChoiceWorkflowV2Step
          result={result.singleChoice ?? {}}
          detail={step.detail as SingleChoiceWorkflowStepDetailFragment}
          onChange={(singleChoice) => onChange({ singleChoice })}
        />
      )
    case WorkflowStepType.CallOut:
      return (
        <CallOutWorkflowV2Step
          result={result.callOut ?? {}}
          detail={step.detail as CallOutWorkflowStepDetailFragment}
          onChange={(callOut) => onChange({ callOut })}
        />
      )
    case WorkflowStepType.MultipleChoice:
      return (
        <MultipleChoiceWorkflowV2Step
          result={result.multipleChoice ?? {}}
          detail={step.detail as MultipleChoiceWorkflowStepDetailFragment}
          onChange={(multipleChoice) => onChange({ multipleChoice })}
        />
      )
    case WorkflowStepType.Text:
      return (
        <TextWorkflowV2Step
          result={result.text ?? {}}
          detail={step.detail as TextWorkflowStepDetailFragment}
          onChange={(text) => onChange({ text })}
        />
      )

    case WorkflowStepType.CustomerType:
      return (
        <CustomerTypeWorkflowV2Step
          result={result.customerType ?? {}}
          detail={step.detail as CustomerTypeWorkflowStepDetailFragment}
          onChange={(customerType) => onChange({ customerType })}
        />
      )
    case WorkflowStepType.OutOfScope:
      return (
        <CallOutWorkflowV2Step
          result={result.outOfScope ?? {}}
          detail={step.detail as CallOutWorkflowStepDetailFragment}
          onChange={(outOfScope) => onChange({ outOfScope })}
        />
      )
    case WorkflowStepType.ServiceType: {
      const topicId = input.topic?.id
      const subtopicId = input.subtopic?.id
      return (
        <ServiceTypeWorkflowV2Step
          topicId={topicId}
          subtopicId={subtopicId}
          result={result.serviceType ?? {}}
          detail={step.detail as ServiceTypeWorkflowStepDetailFragment}
          onChange={(serviceType) => onChange({ serviceType })}
        />
      )
    }
    case WorkflowStepType.Subtopic: {
      const topicId = input.topic?.id
      return (
        <SubtopicWorkflowV2Step
          topicId={topicId}
          result={result.subtopic ?? {}}
          detail={step.detail as SubtopicWorkflowStepDetailFragment}
          onChange={(subtopic) => onChange({ subtopic })}
        />
      )
    }
    case WorkflowStepType.Topic: {
      const tradeId = input.trade?.id
      return (
        <TopicWorkflowV2Step
          tradeId={tradeId}
          result={result.topic ?? {}}
          detail={step.detail as TopicWorkflowStepDetailFragment}
          onChange={(topic) => onChange({ topic })}
        />
      )
    }
    case WorkflowStepType.Trade:
      return (
        <TradeWorkflowV2Step
          result={result.trade ?? {}}
          detail={step.detail as TradeWorkflowStepDetailFragment}
          onChange={(trade) => onChange({ trade })}
        />
      )
    default:
      return <Callout type="failure">Unknown step type</Callout>
  }
}
