export const possibleTypes = {
  Export: ['CsvExport', 'KeypayCsvExport'],
  PerformanceRule: [
    'JobReferralPerformanceRule',
    'OnlineReviewPerformanceRule',
    'SalesPerformanceRule',
    'ManualPerformanceRule',
    'NonePerformanceRule'
  ],
  PerformanceOrigin: [
    'JobReferralPerformanceOrigin',
    'OnlineReviewPerformanceOrigin',
    'SalesPerformanceOrigin',
    'InstallPerformanceOrigin',
    'ManualPerformanceOrigin'
  ],
  PerformanceReviewAggregationBucket: [
    'StandardPerformanceReviewAggregationBucket',
    'UserPerformanceReviewAggregationBucket',
    'BranchPerformanceReviewAggregationBucket',
    'DepartmentPerformanceReviewAggregationBucket',
    'RuleTypePerformanceReviewAggregationBucket',
    'SchemePerformanceReviewAggregationBucket',
    'AmountPerformanceReviewAggregationBucket'
  ],
  PhoneNumberAllocation: ['CampaignPhoneNumberAllocation', 'UserPhoneNumberAllocation'],
  InteractionMessage: ['CallInteractionMessage'],
  InteractionMessageStats: ['CallInteractionMessageStats'],
  InteractionMessageAggregationBucket: [
    'SummaryInteractionMessageAggregationBucket',
    'DateInteractionMessageAggregationBucket'
  ],
  Budget: ['MoneyBudget', 'CountBudget', 'HoursBudget'],
  BudgetInterval: ['MoneyBudgetInterval', 'CountBudgetInterval', 'HoursBudgetInterval'],
  JobStatusDetail: [
    'NotStartedJobStatusDetail',
    'InProgressJobStatusDetail',
    'ActionRequiredJobStatusDetail',
    'OnHoldJobStatusDetail',
    'ReviewJobStatusDetail',
    'CancelledJobStatusDetail',
    'CompleteJobStatusDetail'
  ],
  WorkflowStepDetail: [
    'CallOutWorkflowStepDetail',
    'SingleChoiceWorkflowStepDetail',
    'MultipleChoiceWorkflowStepDetail',
    'TextWorkflowStepDetail',
    'CustomerTypeWorkflowStepDetail',
    'OutOfScopeWorkflowStepDetail',
    'ServiceTypeWorkflowStepDetail',
    'SubtopicWorkflowStepDetail',
    'TopicWorkflowStepDetail',
    'TradeWorkflowStepDetail'
  ],
  WorkflowRule: ['BookingWorkflowRule'],
  WorkflowInput: ['BookingWorkflowInput'],
  WorkflowOutcome: ['BookingWorkflowOutcome']
}
