import { useQuery } from '@apollo/client'
import { EuiFieldSearch, EuiSpacer } from '@elastic/eui'
import { TopicSubmitWorkflowStepDetailInput, TopicWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { useState } from 'react'
import { useDebouncedValue } from 'rooks'
import { SearchTopicsDocument } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface TopicWorkflowV2StepProps extends WorkflowV2StepProps {
  tradeId?: string
  detail: TopicWorkflowStepDetailFragment
  result: Partial<TopicSubmitWorkflowStepDetailInput>
  onChange: (result: TopicSubmitWorkflowStepDetailInput) => void
}

export const TopicWorkflowV2Step = ({ tradeId, result, onChange, detail }: TopicWorkflowV2StepProps) => {
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebouncedValue(query, 150)

  const { data, loading } = useQuery(SearchTopicsDocument, {
    variables: {
      input: {
        filter: {
          ...(debouncedQuery.length ? { query: debouncedQuery } : {}),
          ...(tradeId && tradeId.length ? { tradeId: [tradeId] } : {})
        },
        size: 200
      }
    }
  })

  const topicId = result.topicId
  const topics = data?.searchTopics.results ?? []

  const topicOptions: ListNavOption[] = topics.map((topic) => ({
    value: topic.id,
    label: topic.description ?? topic.name
  }))
  const selectedTopic = topicOptions.find((o) => o.value === topicId)

  return (
    <>
      <Callout type={'script'}>{detail.question}</Callout>
      <EuiSpacer size="s" />
      <EuiFieldSearch placeholder="Search topics" value={query} onChange={(e) => setQuery(e.target.value)} />
      <EuiSpacer size="s" />
      <ListNav
        loading={loading && !topics.length}
        value={selectedTopic}
        options={topicOptions}
        onChange={(topic) => onChange({ topicId: topic.value })}
      />
    </>
  )
}
