import { EuiLink } from '@elastic/eui'
import { InvoiceFragment } from '@fallonsolutions/types'
import { useState } from 'react'
import { SendPaymentLinkForm } from './send-payment-link-form'

export interface SendPaymentLinkButtonProps {
  invoice: InvoiceFragment
}

export const SendPaymentLinkButton = (props: SendPaymentLinkButtonProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  return (
    <>
      <EuiLink onClick={showModal}>Send SMS to {props.invoice?.customer?.billingContact?.detail?.fullName}</EuiLink>
      {isModalVisible && <SendPaymentLinkForm {...props} closeModal={closeModal} />}
    </>
  )
}
