import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiTableSelectionType,
  EuiText
} from '@elastic/eui'
import { MutableRefObject, ReactNode } from 'react'

//import '../static/css/paginated-table.css'

export interface Pagination {
  index: number
  size: number
}

export interface TableProps {
  pagination: Pagination
  onChangePagination: (event: Pagination) => void
}

export interface Sort {
  field: string
  direction: 'asc' | 'desc'
}

interface PaginatedTableProps {
  tableRef?: MutableRefObject<EuiBasicTable | null>
  modelName: string
  total: number
  rows: any[]
  columns: any[]
  pagination: Pagination
  onChangePagination: (event: Pagination) => void
  sorting?: Sort
  onChangeSort?: (event: Sort) => void
  actions?: ReactNode
  footer?: ReactNode
  selection?: EuiTableSelectionType<any>
  itemId?: string | number | ((item: any) => string)
  inMemoryTable?: boolean
}

const PaginatedTable = (props: PaginatedTableProps) => {
  const { actions, footer, selection, itemId, tableRef } = props

  const onChange = (event: any) => {
    const { index, size } = event.page
    props.onChangePagination({ index, size })
    props.onChangeSort?.(event.sort)
  }

  const pagination = {
    pageIndex: props.pagination.index,
    pageSize: props.pagination.size,
    totalItemCount: props.total,
    pageSizeOptions: [20, 50, 100],
    hidePerPageOptions: true
  }

  let table
  if (props.inMemoryTable) {
    table = (
      <EuiInMemoryTable
        items={props.rows}
        columns={props.columns}
        sorting={{ sort: props.sorting } as any}
        pagination={pagination}
        onChange={onChange}
      />
    )
  } else if (props.pagination.size < props.total) {
    table = (
      <EuiBasicTable
        ref={tableRef}
        selection={selection}
        itemId={itemId}
        items={props.rows}
        columns={props.columns}
        pagination={pagination}
        onChange={onChange}
        noItemsMessage={`No ${props.modelName} found`}
        data-test-subj="data-table"
      />
    )
  } else {
    table = (
      <EuiBasicTable
        selection={selection}
        itemId={itemId}
        items={props.rows}
        columns={props.columns}
        noItemsMessage={`No ${props.modelName} found`}
        data-test-subj="data-table"
      />
    )
  }

  return (
    <div>
      <EuiFlexGroup style={{ marginBottom: '12px', minHeight: '30px' }} gutterSize="none" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiText size="s">Found {`${props.total.toLocaleString()} ${props.modelName}`}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem style={{ textAlign: 'right' }} grow={true}>
          {actions}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiPanel paddingSize="m">
        {table}
        {footer ?? <></>}
      </EuiPanel>
      <EuiSpacer size="xxl" />
      <EuiSpacer size="m" />
      <EuiSpacer size="m" />
    </div>
  )
}

export default PaginatedTable
