import { useLazyQuery } from '@apollo/client'
import { EuiSpacer } from '@elastic/eui'
import { ServiceTypeSubmitWorkflowStepDetailInput, ServiceTypeWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { useEffect } from 'react'
import { GetSubtopicServiceTypesDocument, GetTopicServiceTypesDocument } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface ServiceTypeWorkflowV2StepProps extends WorkflowV2StepProps {
  topicId?: string
  subtopicId?: string
  detail: ServiceTypeWorkflowStepDetailFragment
  result: Partial<ServiceTypeSubmitWorkflowStepDetailInput>
  onChange: (result: ServiceTypeSubmitWorkflowStepDetailInput) => void
}

export const ServiceTypeWorkflowV2Step = ({
  topicId,
  subtopicId,
  result,
  onChange,
  detail
}: ServiceTypeWorkflowV2StepProps) => {
  const [getTopicServiceTypes, { data: topicData, loading: topicLoading }] = useLazyQuery(GetTopicServiceTypesDocument)
  const [getSubtopicServiceTypes, { data: subtopicData, loading: subtopicLoading }] = useLazyQuery(
    GetSubtopicServiceTypesDocument
  )

  // Fetch service types for the selected topic or subtopic
  useEffect(() => {
    if (subtopicId) {
      getSubtopicServiceTypes({
        variables: {
          input: {
            id: subtopicId
          }
        }
      })
    } else if (topicId) {
      getTopicServiceTypes({
        variables: {
          input: {
            id: topicId
          }
        }
      })
    }
  }, [topicId, subtopicId])

  const loading = topicLoading || subtopicLoading

  const serviceTypeId = result.serviceTypeId
  const serviceTypes = topicData?.getTopic.topic?.serviceTypes ?? subtopicData?.getSubtopic.subtopic?.serviceTypes ?? []

  const serviceTypeOptions: ListNavOption[] = serviceTypes.map((serviceType) => ({
    value: serviceType.id,
    label: serviceType.description ?? serviceType.name,
    icon: serviceType.iconUrl
  }))
  const selectedServiceType = serviceTypeOptions.find((o) => o.value === serviceTypeId)

  return (
    <>
      <Callout type={'script'}>{detail.question}</Callout>
      <EuiSpacer size="s" />
      <ListNav
        loading={loading && !serviceTypes.length}
        value={selectedServiceType}
        options={serviceTypeOptions}
        onChange={(serviceType) => onChange({ serviceTypeId: serviceType.value })}
      />
    </>
  )
}
