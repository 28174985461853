import { useState } from 'react'
import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight
} from '@elastic/eui'
import { AllBranchesDocument } from '../api/generated-types'
import { useQuery } from '@apollo/client'

interface BranchComboBoxProps {
  branches: EuiComboBoxOptionOption<string>[]
  onChangeBranches: (branches: EuiComboBoxOptionOption<string>[]) => void
}

export const BranchComboBox = (props: BranchComboBoxProps) => {
  const { branches, onChangeBranches } = props

  const [enabled, setEnabled] = useState(branches.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const { data, loading, error } = useQuery(AllBranchesDocument)

  const results = data?.allBranches?.results ?? []
  const options = results.map((branch) => {
    return {
      id: branch.id,
      label: branch.name
    }
  })

  if (error) {
    console.error('error', error)
  }

  const renderOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={true}>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (branches.length <= 0) {
      setEnabled(false)
    }
  }
  const label = 'branch'
  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder="Branches"
        options={options}
        selectedOptions={branches}
        onChange={onChangeBranches}
        isClearable={true}
        renderOption={renderOption}
        rowHeight={40}
        isLoading={loading}
        // onSearchChange={onSearchChange}
        style={{ minWidth: '140px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label={label}
        data-test-subj={label}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Branch
        </EuiButtonEmpty>
      )}
    </form>
  )
}
