import { useQuery } from '@apollo/client'
import { EuiLink, EuiSpacer } from '@elastic/eui'
import { SubtopicSubmitWorkflowStepDetailInput, SubtopicWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { SearchSubtopicsDocument } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface SubtopicWorkflowV2StepProps extends WorkflowV2StepProps {
  topicId?: string
  detail: SubtopicWorkflowStepDetailFragment
  result: Partial<SubtopicSubmitWorkflowStepDetailInput>
  onChange: (result: SubtopicSubmitWorkflowStepDetailInput) => void
}

export const SubtopicWorkflowV2Step = ({ topicId, result, onChange, detail }: SubtopicWorkflowV2StepProps) => {
  const { data, loading } = useQuery(SearchSubtopicsDocument, {
    variables: {
      input: {
        filter: {
          ...(topicId ? { topicId: [topicId] } : {})
        },
        size: 200
      }
    }
  })

  const subtopicId = result.subtopicId
  const subtopics = data?.searchSubtopics.results ?? []

  const subtopicOptions: ListNavOption[] = subtopics.map((subtopic) => ({
    value: subtopic.id,
    label: subtopic.description ?? subtopic.name
  }))
  const selectedServiceType = subtopicOptions.find((o) => o.value === subtopicId)

  return (
    <>
      <Callout type={'script'}>{detail.question}</Callout>
      <EuiSpacer size="s" />
      <ListNav
        loading={loading && !subtopics.length}
        value={selectedServiceType}
        options={subtopicOptions}
        onChange={(subtopic) => onChange({ subtopicId: subtopic.value })}
      />
      {!loading && subtopics.length === 0 && (
        <p>
          No subtopics found. <EuiLink onClick={() => onChange({ subtopicId: 'SKIP' })}>Skip</EuiLink>
        </p>
      )}
    </>
  )
}
