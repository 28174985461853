import { JobTopic, JobTopicTrees, JobTopicType } from '@fallonsolutions/types'
import { compact, orderBy } from 'lodash-es'
import {
  CommunicationChannelSelection,
  CustomerType,
  LegacyBookingWindow,
  MembershipStatusType,
  TradeType
} from '../../api/generated-types'
import RawJSON from '../../common/raw-json'
import { skipWrapUpSections } from '../../common/skip-wrap-sections'
import { makeHumanReadable } from '../../common/utils'
import { AllAction, useDecisionPoint } from '../../decision-point/use-decision-point'
import { GetCustomerMemberships } from '../helpers/get-customer-memberships'
import { UseSandbox } from '../helpers/get-sandbox-state'
import { generateInternalNotes } from '../helpers/internal-notes-generator'
import { GetServiceArea } from '../helpers/service-area'
import { TopicActionResult } from '../topics/action-topic-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { AdditionalTradeAction, AdditionalTradeActionResult } from './action-additional-trade'
import { BookingWrapUpAction, BookingWrapUpActionResult } from './action-booking-wrapup'
import { ContactActionResult } from './action-contact'
import { CreateCustomerAction, CreateCustomerActionResult } from './action-create-customer'
import { CreateJobAction, CreateJobActionResult } from './action-create-job'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { CustomerPropertyAction, CustomerPropertyActionResult } from './action-customer-property'
import {
  ContactRole,
  CustomerQualificationAction,
  CustomerQualificationActionResult
} from './action-customer-qualification'
import { JobClassificationAction } from './action-job-classification'
import { JobClassificationActionResult } from './action-job-classification-types'
import { JobCloseAction, JobCloseActionResult } from './action-job-close'
import { JobSummaryAction, JobSummaryActionResult } from './action-job-summary'
import { PostBookingAction, PostBookingActionResult } from './action-post-booking'
import { SelectJobTopicAction, SelectJobTopicActionResult } from './action-select-job-topic'
import {
  ScheduleReservationAction,
  ScheduleReservationActionResult
} from './schedule-reservation/action-schedule-reservation'

export interface NewJobActionInput extends BaseActionInput {
  contact?: ContactActionResult
  customer?: CustomerActionResult
  updateContainerCustomer?: (customerId: string) => void
}

export interface NewJobActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  customerProperty?: CustomerPropertyActionResult
  customerQualification?: CustomerQualificationActionResult
  jobTopic?: SelectJobTopicActionResult
  jobClassification?: JobClassificationActionResult
  scheduleReservation?: ScheduleReservationActionResult
  jobClose?: JobCloseActionResult
  createCustomer?: CreateCustomerActionResult
  jobSummary?: JobSummaryActionResult
  bookingWrapUp?: BookingWrapUpActionResult
  additionalTrade?: AdditionalTradeActionResult
  createJob?: CreateJobActionResult
  postBooking?: PostBookingActionResult
  managedToBookNewJob?: boolean
  wasItAnExistingCustomer?: boolean
  fastTrack?: {
    newCustomer?: boolean
    fastBooking?: boolean
  }
  existingJobId?: string | undefined
}

// eslint-disable-next-line max-lines-per-function,complexity
export const NewJobAction = (props: WorkflowActionProps<NewJobActionInput, NewJobActionResult>) => {
  const { input, onUpdate, result } = props
  //const appContext = useApp()
  const { contact, customer: selectCustomer, updateContainerCustomer } = input
  const {
    customerQualification,
    jobTopic,
    jobClassification,
    jobClose,
    jobSummary,
    bookingWrapUp,
    additionalTrade,
    postBooking,
    createCustomer,
    createJob,
    fastTrack,
    existingJobId
  } = result ?? {}
  const { fastBooking, newCustomer } = fastTrack ?? {}
  const customerPropertyResult = result?.customerProperty
  const [sandbox] = UseSandbox()
  const tenantId = !sandbox ? 'fallonsolutions' : 'fallonsolutions-sandbox'
  const { property } = customerPropertyResult ?? {}
  const customerResult = result?.customer
  const customer = createCustomer?.customer ?? customerResult?.customer ?? undefined
  const scheduleReservationResult = result?.scheduleReservation
  const { reservationDetail } = scheduleReservationResult ?? {}
  const job = createJob?.job
  const existingCustomer = customerQualification?.existingCustomer === true
  const m = GetCustomerMemberships({
    tenantId,
    customerId: customer?.id,
    status: [MembershipStatusType.Active, MembershipStatusType.Expired]
  }) // don't get pending, pending is when a membership is sold but not currently activated
  const memberships = orderBy(m, 'expiryDate', 'desc') // get the memberships sorted by how far into the future they expire

  const latestMembership = memberships
    .filter((m) => m.status.status === MembershipStatusType.Active)
    .find((m) => m?.location?.propertyId === property?.id)

  const { canChangeEnquiryCustomerMembership } = useDecisionPoint(AllAction.ChangeEnquiryCustomerMembership)
  const canUserChangeCustomerMembership = !!canChangeEnquiryCustomerMembership().ok

  const setCustomer = (customer: CustomerActionResult) => {
    onUpdate({ ...result, customer })
    updateContainerCustomer && updateContainerCustomer(customer?.customer?.id ?? '')
  }
  const setCustomerProperty = (customerProperty: CustomerPropertyActionResult) => {
    const { property } = customerProperty ?? {}
    const doesPropertyHaveMembership = memberships
      ?.filter((m) => m.status.status === MembershipStatusType.Active)
      .find((m) => m?.location?.propertyId === property?.id)
    const { contactRole } = customerQualification ?? {}

    // only homeowners can be members, all other customer types are considered commercial and cannot be members
    const existingMember = !!doesPropertyHaveMembership && contactRole === ContactRole.HomeOwner
    const existingCustomer = existingMember ? true : customerQualification?.existingCustomer

    onUpdate({
      ...result,
      customerProperty,
      ...(!!customerQualification &&
        // if user can change enquiry customer membership, do not overwrite what they have selected
        !canUserChangeCustomerMembership && {
          customerQualification: {
            ...customerQualification,
            existingMember,
            existingCustomer
          }
        })
    })
  }
  const setCustomerQualification = (customerQualification: CustomerQualificationActionResult) => {
    onUpdate({ ...result, customerQualification })
  }

  const setJobTopic = (jobTopic?: SelectJobTopicActionResult) => onUpdate({ ...result, jobTopic })
  const setJobClassification = (jobClassification?: JobClassificationActionResult) =>
    onUpdate({ ...result, jobClassification })
  const setScheduleReservation = (scheduleReservation?: ScheduleReservationActionResult) => {
    // if fast booking is enabled we want to skip the job close step
    if (scheduleReservation?.actionCompleted === true && !!fastBooking) {
      skipWrapUpSections({ onUpdate, result: { ...result, scheduleReservation } })
      console.log('schedule reservation action completed & fast booking is enabled, moving to job close action')
    } else {
      onUpdate({ ...result, scheduleReservation })
    }
  }
  const setJobClose = (jobClose?: JobCloseActionResult) => onUpdate({ ...result, jobClose })
  const setCreateCustomer = (createCustomer?: CreateCustomerActionResult) => onUpdate({ ...result, createCustomer })
  const setJobSummary = (jobSummary?: JobSummaryActionResult) => onUpdate({ ...result, jobSummary })
  const setCreateJob = (createJob?: CreateJobActionResult) => onUpdate({ ...result, createJob })

  const setBookingWrapUp = (bookingWrapUp?: BookingWrapUpActionResult) => {
    console.log('set booking wrap up', bookingWrapUp)
    if (bookingWrapUp?.updateCustomerDetails === true) {
      //open customer, and reset bookingWrap up result
      if (result?.createCustomer) {
        onUpdate({
          ...result,
          bookingWrapUp: undefined, // { ...bookingWrapUp, updateCustomerDetails: false },
          createCustomer: { ...result?.createCustomer, actionCompleted: false }
        })
      } else {
        onUpdate({
          ...result,
          bookingWrapUp: undefined, // { ...bookingWrapUp, updateCustomerMobile: false },
          customer: { ...result?.customer, actionCompleted: false }
        })
      }
    } else {
      onUpdate({ ...result, bookingWrapUp })
    }
  }

  const setAdditionalTrade = (additionalTrade?: AdditionalTradeActionResult) => onUpdate({ ...result, additionalTrade })
  const setPostBooking = (postBooking?: PostBookingActionResult) =>
    onUpdate({ ...result, postBooking, actionCompleted: postBooking?.actionCompleted === true })

  // changing from topic to another, set autocompleted to true to minimize button presses
  const handleJobTopicChanged = (jobTopic: JobTopic, topic?: TopicActionResult) =>
    onUpdate({
      ...result,
      jobTopic: { actionCompleted: true, topic: jobTopic },
      jobClassification: {
        topic: {
          ...topic
        },
        trade: undefined,
        type: undefined,
        category: undefined,
        customerType: topic?.customerType
      }
    })

  const isNewCustomer =
    (!!customerResult?.actionCompleted && !customerResult?.customer) ||
    (input.customer?.actionCompleted === true && newCustomer !== false)

  const isJobCreated = !!createJob?.job?.id
  const companyId = 'fallonsolutions'
  const { serviceArea: returnedServiceArea } = GetServiceArea({ companyId, suburbId: property?.suburb?.id })
  const serviceArea = returnedServiceArea ?? undefined // graphql can also return null, in that case just convert it to undefined

  const getGeneratedInternalNotes = (): string => {
    if (
      !customerQualification?.contactRole ||
      !jobClassification?.customerType ||
      !jobClassification?.type ||
      !jobClassification?.trade ||
      !jobClassification?.category ||
      !jobClassification?.topic ||
      !customer?.phone
    ) {
      console.log('missing a field required for internal notes generation')
      console.log(
        `customer qualification ${customerQualification} customer ${customer} jobClassification ${jobClassification} property ${property}`
      )
      return ''
    }
    return generateInternalNotes({
      contactRole: customerQualification?.contactRole,
      customerType: jobClassification?.customerType,
      jobType: jobClassification?.type,
      trade: jobClassification?.trade,
      jobTopic: jobTopic?.topic?.reference,
      existingCustomer: customerQualification?.existingCustomer,
      existingMember: customerQualification?.existingMember,
      contactPhone: customer?.phone,
      bookingWindow: reservationDetail?.bookingWindow,
      customerInfo: {
        name: customer?.fullName ?? 'unknown'
      },
      issueType: (jobClassification.topic as any).drainIssue,
      afterHours: reservationDetail?.bookingWindow?.id === LegacyBookingWindow.AfterHours,
      jobClassification: jobClassification,
      serviceArea: serviceArea,
      customerMembership: latestMembership,
      // topic: jobTopic?.topic,
      bookingWrapUp,
      memberships: memberships,
      appliedDiscount: jobClose?.appliedDiscount
    })
  }

  const getGeneratedWorkRequiredNotes = () => {
    if (!jobClassification) return ''
    const { customerType, trade, type, category } = jobClassification
    const divider = ' → '
    const classificationSummary =
      compact([
        customerType ? `${makeHumanReadable(customerType)}` : undefined,
        trade ? `${makeHumanReadable(trade)}` : undefined,
        type ? `${makeHumanReadable(type)}` : undefined,
        category ? `${makeHumanReadable(category)}` : undefined,
        !!jobTopic?.topic?.label && jobTopic?.topic.reference !== JobTopicType.Other
          ? `${makeHumanReadable(jobTopic.topic?.label)}`
          : undefined
      ]).join(divider) + '\n\n'
    const jobDescription = 'Job description: ' + (jobClassification.topic?.jobDescription ?? '') + '\n\n---\n\n'
    return classificationSummary + jobDescription + (jobClassification.topic?.workRequiredNotes ?? '')
  }
  return (
    <>
      <CustomerAction
        input={{
          contact: input.contact,
          allowCreate: true,
          editable: !isJobCreated,
          isNewCustomer,
          allowContinueWithoutCustomer: true
        }}
        result={result?.customer}
        onUpdate={setCustomer}
      />
      {customerResult?.actionCompleted && (
        <CustomerQualificationAction
          input={{ customerId: customer?.id, editable: !isJobCreated, isNewCustomer }}
          result={{ ...customerQualification, customerMemberships: memberships }}
          onUpdate={setCustomerQualification}
        />
      )}
      {customerResult?.customer && customerQualification?.actionCompleted && (
        <CustomerPropertyAction
          input={{
            customerId: customerResult.customer.id,
            editable: !isJobCreated,
            customerQualification: { ...customerQualification, customerMemberships: memberships }
          }}
          result={result?.customerProperty}
          onUpdate={setCustomerProperty}
        />
      )}
      {customerQualification?.actionCompleted && (isNewCustomer || (customer && property)) && (
        <SelectJobTopicAction
          jobTopicTrees={JobTopicTrees}
          input={{ editable: !isJobCreated }}
          result={jobTopic}
          onUpdate={setJobTopic}
        />
      )}
      {jobTopic?.actionCompleted &&
        jobTopic.topic &&
        customerQualification?.actionCompleted &&
        (isNewCustomer || (customer && property)) && (
          <JobClassificationAction
            input={{
              contact: input.contact,
              editable: !isJobCreated,
              jobTopic: jobTopic.topic,
              workflowV2: jobTopic.workflowV2,
              customer: customerResult?.customer,
              customerContactRole: customerQualification.contactRole,
              existingCustomer: customerQualification.existingCustomer,
              property: customerPropertyResult?.property,
              serviceArea,
              handleJobTopicChanged: handleJobTopicChanged
            }}
            result={jobClassification}
            onUpdate={setJobClassification}
          />
        )}
      {jobClassification?.actionCompleted && jobTopic?.topic && (
        <ScheduleReservationAction
          input={{
            topic: jobTopic.topic,
            jobClassification,
            editable: !isJobCreated,
            jobCreated: !!createJob?.jobCreated,
            suburbId: property?.suburb?.id ?? input.contact?.suburb?.id,
            customerContact: customerQualification,
            companyId
          }}
          result={scheduleReservationResult}
          onUpdate={setScheduleReservation}
        />
      )}
      {customerQualification && jobTopic && jobClassification && scheduleReservationResult?.actionCompleted && (
        <div data-test-id="action-job-topic-close">
          <JobCloseAction
            input={{
              customerQualification,
              jobTopic,
              bookingWindow: scheduleReservationResult.bookingWindow,
              timeWindow: scheduleReservationResult.reservationDetail?.timeWindow,
              jobClassification,
              afterHours:
                scheduleReservationResult.reservationDetail?.bookingWindow?.id === LegacyBookingWindow.AfterHours,
              existingMember: customerQualification?.existingMember ?? false,
              editable: !isJobCreated,
              reservationDate: scheduleReservationResult.reservationDetail?.startDate,
              serviceArea
            }}
            result={jobClose}
            onUpdate={setJobClose}
          />
        </div>
      )}
      {!customerResult?.customer &&
        jobClose?.actionCompleted &&
        scheduleReservationResult?.actionCompleted &&
        customerQualification?.actionCompleted && (
          <CreateCustomerAction
            input={{
              contact: input.contact,
              editable: !isJobCreated,
              customerContactRole: customerQualification.contactRole,
              customerResult
            }}
            result={createCustomer}
            onUpdate={setCreateCustomer}
          />
        )}
      {createCustomer?.actionCompleted &&
        scheduleReservationResult?.actionCompleted &&
        createCustomer?.customer?.id &&
        customerQualification?.actionCompleted && (
          <CustomerPropertyAction
            input={{
              customerId: createCustomer.customer.id,
              editable: !isJobCreated,
              customerQualification
            }}
            result={result?.customerProperty}
            onUpdate={setCustomerProperty}
          />
        )}
      {jobClose?.actionCompleted &&
        customer &&
        customerQualification &&
        property &&
        jobClassification?.customerType &&
        scheduleReservationResult?.actionCompleted &&
        reservationDetail && (
          // {customer && customerQualification && jobClassification?.customerType && jobSummary?.actionCompleted && (
          <BookingWrapUpAction
            input={{
              existingCustomer: customerQualification.existingCustomer ?? false,
              customerType: jobClassification.customerType,
              editable: !isJobCreated,
              trade: jobClassification.trade ?? TradeType.None,
              customer,
              defaultBookingConfirmation: reservationDetail.reserveNow
                ? CommunicationChannelSelection.All
                : CommunicationChannelSelection.None,
              bookingConfirmation: bookingWrapUp?.bookingConfirmation,
              reserveNow: reservationDetail.reserveNow
            }}
            result={bookingWrapUp}
            onUpdate={setBookingWrapUp}
          />
        )}
      {bookingWrapUp?.actionCompleted &&
        scheduleReservationResult?.actionCompleted &&
        customer &&
        property &&
        jobClassification &&
        reservationDetail && (
          <JobSummaryAction
            input={{
              customer,
              property,
              reservationDetail,
              defaultWorkRequiredNotes: getGeneratedWorkRequiredNotes(),
              defaultInternalNotes: getGeneratedInternalNotes(),
              editable: !isJobCreated
            }}
            result={jobSummary}
            onUpdate={setJobSummary}
          />
        )}
      {jobClassification?.customerType === CustomerType.Domestic && jobSummary?.actionCompleted && (
        <AdditionalTradeAction
          input={{ editable: !isJobCreated }}
          result={additionalTrade}
          onUpdate={setAdditionalTrade}
        />
      )}
      {customer &&
        property &&
        jobTopic &&
        jobClassification &&
        reservationDetail &&
        (additionalTrade?.actionCompleted ||
          (jobClassification?.customerType !== CustomerType.Domestic && bookingWrapUp?.actionCompleted)) && (
          <CreateJobAction
            input={{
              customer,
              existingJobId,
              fastTrackBookingEnabled: !!fastBooking,
              contacts: selectCustomer?.customer?.contacts ?? [],
              contact,
              property,
              jobTopic,
              jobClassification,
              jobClose,
              reservationDetail,
              isExistingMember: customerQualification?.existingMember ?? false,
              defaultWorkRequiredNotes: getGeneratedWorkRequiredNotes(),
              defaultInternalNotes: getGeneratedInternalNotes(),
              bookingConfirmation: bookingWrapUp?.bookingConfirmation,
              activeMembership: latestMembership
            }}
            result={createJob}
            onUpdate={setCreateJob}
          />
        )}
      {job && customer && createJob?.actionCompleted && (
        <PostBookingAction
          input={{
            job,
            customer,
            existingCustomer,
            reservationDetail,
            emailSent: bookingWrapUp?.bookingConfirmation === CommunicationChannelSelection.All
          }}
          result={postBooking}
          onUpdate={setPostBooking}
        />
      )}
      <RawJSON data={result} />
    </>
  )
}
