import { EuiLoadingSpinner } from '@elastic/eui'
import { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuthenticated } from '../auth/authenticated-context'
import Signout from '../auth/signout'
import InteractionMessageRoutes from '../interactions/interaction-message-routes'
import { UnauthorizedError } from './unauthorized-error'

const HomeContainer = lazy(() => import('../home/home-container'))
const ActivityRoutes = lazy(() => import('../activities/activity-routes'))
const AppointmentRoutes = lazy(() => import('../appointments/appointment-routes'))
const AttributeRoutes = lazy(() => import('../attributes/attribute-routes'))
const AuthCallback = lazy(() => import('../auth/authentication-callback'))
const BookingConfigRoutes = lazy(() => import('../booking-config/booking-config-routes'))
const BudgetRoutes = lazy(() => import('../budgets/budget-routes'))
const CallCentreRoutes = lazy(() => import('../call-centre/call-centre-routes'))
const ChangelogRoutes = lazy(() => import('../changelog/changelog-routes'))
const CommentRoutes = lazy(() => import('../comments/comment-routes'))
const ComplaintRoutes = lazy(() => import('../complaints/complaint-routes'))
const ContactRoutes = lazy(() => import('../contacts/contact-routes'))
const CostRoutes = lazy(() => import('../costs/cost-routes'))
const CustomerLocationRoutes = lazy(() => import('../customer-locations/customer-location-routes'))
const CustomerRoutes = lazy(() => import('../customers/customer-routes'))
const DiscountRoutes = lazy(() => import('../discounts/discount-routes'))
const DispatchRoutes = lazy(() => import('../dispatch/dispatch-routes'))
const DocumentationRoutes = lazy(() => import('../documentation/documentation-routes'))
const DownloadRoutes = lazy(() => import('../downloads/download-routes'))
const EnquiryRoutes = lazy(() => import('../enquiries/enquiry-routes'))
const ExportRoutes = lazy(() => import('../exports/export-routes'))
const FinanceRoutes = lazy(() => import('../finance/finance-routes'))
const GlossaryRoutes = lazy(() => import('../glossary/glossary-routes'))
const HealthRoutes = lazy(() => import('../health/health-routes'))
const InspectionRoutes = lazy(() => import('../inspections/inspection-routes'))
const InvoiceRoutes = lazy(() => import('../invoices/invoice-routes'))
const RiskAssessmentRoutes = lazy(() => import('../risk-assessment/risk-assessment-routes'))
const ItemRoutes = lazy(() => import('../pricelist/item-routes'))
const JobReportRoutes = lazy(() => import('../job-reports/job-report-routes'))
const JobRoutes = lazy(() => import('../jobs/job-routes'))
const JobTypeRoutes = lazy(() => import('../job-types/job-type-routes'))
const LabourRateRoutes = lazy(() => import('../labour-rates/labour-rate-routes'))
const LeaderboardRoutes = lazy(() => import('../leaderboards/leaderboard-routes'))
const MarketingRoutes = lazy(() => import('../marketing/marketing-routes'))
const MediaRoutes = lazy(() => import('../media/media-routes'))
const MembershipRoutes = lazy(() => import('../memberships/membership-routes'))
const OptionSheetTemplateRoutes = lazy(() => import('../option-sheet-templates/option-sheet-template-routes'))
const PartRoutes = lazy(() => import('../parts/part-routes'))
const PaymentRoutes = lazy(() => import('../payments/payment-routes'))
const PerformanceRoutes = lazy(() => import('../performance/performance-routes'))
const PhonebookRoutes = lazy(() => import('../phonebook/phonebook-routes'))
const PricelistRoutes = lazy(() => import('../pricelist/pricelist-routes'))
const PropertyRoutes = lazy(() => import('../properties/property-routes'))
const PublicHolidayRoutes = lazy(() => import('../public-holidays/public-holiday-routes'))
const ReportRoutes = lazy(() => import('../reports/report-routes'))
const ReviewRoutes = lazy(() => import('../reviews/review-routes'))
const SalesCategoryRoutes = lazy(() => import('../sales-categories/sales-category-routes'))
const SalesEventRoutes = lazy(() => import('../sales-events/sales-event-routes'))
const ScheduledServiceRoutes = lazy(() => import('../scheduled-service/scheduled-service-routes'))
const ScheduleEventContainer = lazy(() => import('../schedule/schedule-event-container'))
const ScheduleRoutes = lazy(() => import('../schedule/schedule-routes'))
const ServiceAreaRoutes = lazy(() => import('../service-area/service-area-routes'))
const SettingsRoutes = lazy(() => import('../settings/settings-routes'))
const SSORoutes = lazy(() => import('../sso/sso-routes'))
const TeamRoutes = lazy(() => import('../teams/team-routes'))
const TenantConfigRoutes = lazy(() => import('../tenant-config/tenant-config-routes'))
const TimesheetRoutes = lazy(() => import('../timesheets/timesheet-routes'))
const TransactionRoutes = lazy(() => import('../transactions/transaction-routes'))
const UserRoutes = lazy(() => import('../users/user-routes'))
const WorkflowRoutes = lazy(() => import('../workflow/workflow-routes'))
const WorkflowV2Routes = lazy(() => import('../workflow-v2/workflow-v2-routes'))

const AuthenticatedRoutes = () => {
  const userFragment = useAuthenticated().userFragment

  const canViewPricingCalculation = userFragment.permissions?.viewPricingCalculation === true
  const canViewTimesheetsForAllUsers = userFragment.permissions?.viewTimesheets === true

  const webAppRoutes = (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        {/* <Route path="/auth/callback" component={AuthCallback} /> */}
        <Route path="/signout" component={Signout} />
        <Route path="/sso" component={SSORoutes} />
        <Route path="/attributes" component={AttributeRoutes} />
        <Route path="/activities" component={ActivityRoutes} />
        <Route path="/appointments" component={AppointmentRoutes} />
        <Route path="/booking/config" component={BookingConfigRoutes} />
        <Route path="/budgets" component={BudgetRoutes} />
        <Route path="/call-centre" component={CallCentreRoutes} />
        <Route path="/changelog" component={ChangelogRoutes} />
        <Route path="/comments" component={CommentRoutes} />
        <Route path="/complaints" component={ComplaintRoutes} />
        <Route path="/contacts" component={ContactRoutes} />
        <Route path="/costs" component={CostRoutes} />
        <Route path="/customers" component={CustomerRoutes} />
        <Route path="/discounts" component={DiscountRoutes} />
        <Route path="/dispatch" component={DispatchRoutes} />
        <Route path="/docs" component={DocumentationRoutes} />
        <Route path="/downloads" component={DownloadRoutes} />
        <Route path="/enquiries" component={EnquiryRoutes} />
        <Route path="/exports" component={ExportRoutes} />
        <Route path="/finance" component={FinanceRoutes} />
        <Route path="/glossary" component={GlossaryRoutes} />
        <Route path="/health" component={HealthRoutes} />
        <Route path="/inspections" component={InspectionRoutes} />
        <Route path="/interaction-message" component={InteractionMessageRoutes} />
        <Route path="/invoices" component={InvoiceRoutes} />
        <Route path="/locations" component={CustomerLocationRoutes} />
        <Route path="/risk-assessments" component={RiskAssessmentRoutes} />
        <Route path="/items" component={ItemRoutes} />
        <Route path="/jobs" component={JobRoutes} />
        <Route path="/job-reports" component={JobReportRoutes} />
        <Route path="/job-types" component={JobTypeRoutes} />
        {canViewPricingCalculation && <Route path="/labour-rates" component={LabourRateRoutes} />}
        <Route path="/leaderboards" component={LeaderboardRoutes} />
        <Route path="/launch" component={launchMobileApp} />
        <Route path="/marketing" component={MarketingRoutes} />
        <Route path="/media" component={MediaRoutes} />
        <Route path="/memberships" component={MembershipRoutes} />
        <Route path="/option-sheet-templates" component={OptionSheetTemplateRoutes} />
        {canViewPricingCalculation && <Route path="/parts" component={PartRoutes} />}
        <Route path="/payments" component={PaymentRoutes} />
        <Route path="/performance" component={PerformanceRoutes} />
        <Route path="/phonebook" component={PhonebookRoutes} />
        <Route path="/pricelists" component={PricelistRoutes} />
        <Route path="/properties" component={PropertyRoutes} />
        <Route path="/public-holidays" component={PublicHolidayRoutes} />
        <Route path="/reports" component={ReportRoutes} />
        <Route path="/reviews" component={ReviewRoutes} />
        <Route path="/sales" component={SalesEventRoutes} />
        <Route path="/sales-categories" component={SalesCategoryRoutes} />
        <Route path="/scheduled-services" component={ScheduledServiceRoutes} />
        <Route path="/schedule" component={ScheduleRoutes} />
        <Route path="/settings" component={SettingsRoutes} />
        <Route path="/skills" component={AttributeRoutes} />
        <Route path="/service-areas" component={ServiceAreaRoutes} />
        <Route path="/teams" component={TeamRoutes} />
        {canViewTimesheetsForAllUsers && <Route path="/timesheets" component={TimesheetRoutes} />}
        <Route path="/transactions" component={TransactionRoutes} />
        <Route path="/users" component={UserRoutes} />
        <Route path="/workflow" component={WorkflowRoutes} />
        <Route path="/workflow-v2" component={WorkflowV2Routes} />
        <Route path="/tenant-config" component={TenantConfigRoutes} />
        <Route exact path="/event/:id" component={ScheduleEventContainer} />
      </Switch>
    </Suspense>
  )

  const fieldAppRoutes = (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        <Route path="/auth/callback" component={AuthCallback} />
        <Route path="/signout" component={Signout} />
        <Route path="/jobs" component={JobRoutes} />
        <Route path="/pricelists" component={PricelistRoutes} />
        <Route path="/settings" component={SettingsRoutes} />
        <Route exact path="/event/:id" component={ScheduleEventContainer} />
      </Switch>
    </Suspense>
  )

  const renderView = () => {
    const webAppAccess = userFragment.permissions?.webApp === true
    const fieldAppAccess = userFragment.permissions?.fieldApp === true
    console.log(
      `authenticated-routes: ${
        webAppAccess ? 'webAppRoutes' : fieldAppAccess ? 'fieldAppRoutes' : 'no routes available'
      }`
    )
    if (webAppAccess) {
      return webAppRoutes
    }
    if (fieldAppAccess) {
      return fieldAppRoutes
    }
    return <UnauthorizedError message={'Not authorized.'} />
  }

  return renderView()
}

const launchMobileApp = (): null => {
  console.log('launching ios app...')
  window.location.href = 'fs://welcome'
  return null
}

export default AuthenticatedRoutes
