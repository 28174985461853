import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'
import {
  CustomerType,
  ServiceTypeSubmitWorkflowStepDetailInput,
  SingleChoiceSubmitWorkflowStepDetailInput,
  SubtopicSubmitWorkflowStepDetailInput,
  TopicSubmitWorkflowStepDetailInput,
  TradeSubmitWorkflowStepDetailInput,
  WorkflowCurrentStepFragment,
  WorkflowNextStepFragment,
  WorkflowStepResultFragment,
  WorkflowStepType
} from '@fallonsolutions/types'
import { useEffect, useState } from 'react'
import { SubmitWorkflowStepDetailInput, SubmitWorkflowStepInput, WorkflowFragment } from '../api/generated-types'
import { BookingConfigFlowChartSidebar } from '../booking-config/explorer/booking-config-flow-chart-sidebar'
import { Callout } from '../common/callout'
import { WorkflowV2StepView } from './workflow-step/workflow-v2-step-view'
import { WorkflowV2Header } from './workflow-v2-header'

export interface WorkflowV2ViewProps {
  workflow: WorkflowFragment
  loading?: boolean
  onNext: (input: SubmitWorkflowStepInput, nextStep?: WorkflowNextStepFragment) => void
  onBack: () => void
  onReset: () => void
}

export const WorkflowV2View = ({ loading, workflow, onNext, onBack, onReset }: WorkflowV2ViewProps) => {
  const currentStep = workflow.currentStep
  const step = currentStep?.step
  const isFirstStep = !workflow.steps.length
  const input = workflow.state.input
  const autoSubmit =
    workflow.currentStep?.step.type &&
    [
      WorkflowStepType.CustomerType,
      WorkflowStepType.Trade,
      WorkflowStepType.Topic,
      WorkflowStepType.Subtopic,
      WorkflowStepType.ServiceType,
      WorkflowStepType.SingleChoice
    ].includes(workflow.currentStep.step.type)

  const stepResult = workflow.steps.find((s) => s.step.id === step?.id)

  const [result, setResult] = useState<SubmitWorkflowStepDetailInput | undefined>(
    step ? getInitialResult(currentStep, stepResult) : undefined
  )

  useEffect(() => {
    setResult(step ? getInitialResult(currentStep, stepResult) : undefined)
  }, [workflow.currentStep?.step])

  const onChangeResult = (result: SubmitWorkflowStepDetailInput) => {
    setResult(result)
    if (result && autoSubmit) {
      handleSubmit(result)
    }
  }

  const handleOnNext = () => {
    if (result) {
      handleSubmit(result)
    }
  }

  const handleSubmit = (result: SubmitWorkflowStepDetailInput) => {
    if (step) {
      const nextStep = workflow.currentStep?.nextSteps.find(
        (s) => s.choiceId === result.singleChoice?.choiceId || !s.choiceId
      )
      onNext(
        {
          workflowId: workflow.id,
          stepId: step.id,
          detail: result
        },
        nextStep
      )
    }
  }

  const inputFields = [
    {
      name: 'Customer type',
      value: input.customerType ?? ''
    },
    {
      name: 'Trade',
      value: input.trade?.name ?? ''
    },
    {
      name: 'Topic',
      value: input.topic?.name ?? ''
    },
    {
      name: 'Service type',
      value: input.serviceType?.name ?? ''
    },
    {
      name: 'Subtopic',
      value: input.subtopic?.name ?? ''
    },
    ...(input.steps
      ?.filter((step) =>
        [WorkflowStepType.SingleChoice, WorkflowStepType.MultipleChoice, WorkflowStepType.Text].includes(step.step.type)
      )
      .map((step) => ({
        name: step.step.name,
        value: step.choice?.label ?? step.value ?? ''
      })) ?? [])
  ]

  const isValid = step && result ? validateResult(step.type, result) : false

  return (
    <>
      <div style={{ width: '100%', minHeight: '720px', position: 'relative' }}>
        <BookingConfigFlowChartSidebar inputFields={inputFields} outcome={workflow.state.outcome} />

        <div style={{ maxWidth: '900px' }}>
          <div>
            <EuiPanel hasShadow={false} hasBorder={true}>
              <WorkflowV2Header loading={loading} workflow={workflow} onBack={onBack} onReset={onReset} />
              <EuiSpacer size="m" />
              {step && result ? (
                <WorkflowV2StepView input={input} result={result} onChange={onChangeResult} step={step} />
              ) : loading ? (
                <EuiLoadingSpinner />
              ) : (
                <Callout title="Workflow complete" />
              )}
            </EuiPanel>
          </div>
          <EuiSpacer />
          <EuiFlexGroup>
            {!isFirstStep && (
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={onBack} isDisabled={loading}>
                  Back
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={true} />
            {step && !autoSubmit && (
              <EuiFlexItem grow={false}>
                <EuiButton onClick={handleOnNext} color="primary" fill isLoading={loading} isDisabled={!isValid}>
                  Next
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      </div>
    </>
  )
}

const validateResult = (type: WorkflowStepType, result: SubmitWorkflowStepDetailInput): boolean => {
  switch (type) {
    case WorkflowStepType.CallOut:
      return true
    case WorkflowStepType.SingleChoice:
      return !!result.singleChoice?.choiceId
    case WorkflowStepType.MultipleChoice:
      return !!result.multipleChoice?.choiceIds?.length
    case WorkflowStepType.Text:
      return !!result.text?.value
    case WorkflowStepType.CustomerType:
      return !!result.customerType?.customerType
    case WorkflowStepType.OutOfScope:
      return true
    case WorkflowStepType.ServiceType:
      return !!result.serviceType?.serviceTypeId
    case WorkflowStepType.Subtopic:
      return !!result.subtopic?.subtopicId
    case WorkflowStepType.Topic:
      return !!result.topic?.topicId
    case WorkflowStepType.Trade:
      return !!result.trade?.tradeId
    default:
      throw new Error(`Unsupported step type: ${type}`)
  }
}

const getInitialResult = (
  currentStep: WorkflowCurrentStepFragment,
  stepResult?: WorkflowStepResultFragment
): SubmitWorkflowStepDetailInput => {
  switch (currentStep.step.type) {
    case WorkflowStepType.CallOut:
      return {
        callOut: {
          complete: true
        }
      }
    case WorkflowStepType.SingleChoice:
      return {
        singleChoice: {} as SingleChoiceSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.MultipleChoice:
      return {
        multipleChoice: {
          choiceIds: []
        }
      }
    case WorkflowStepType.Text:
      return {
        text: {
          value: stepResult?.value ?? ''
        }
      }
    case WorkflowStepType.CustomerType:
      return {
        customerType: {
          customerType: CustomerType.None
        }
      }
    case WorkflowStepType.OutOfScope:
      return {
        outOfScope: {
          complete: true
        }
      }
    case WorkflowStepType.ServiceType:
      return {
        serviceType: {} as ServiceTypeSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Subtopic:
      return {
        subtopic: {} as SubtopicSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Topic:
      return {
        topic: {} as TopicSubmitWorkflowStepDetailInput
      }
    case WorkflowStepType.Trade:
      return {
        trade: {} as TradeSubmitWorkflowStepDetailInput
      }
    default:
      throw new Error(`Unsupported step type: ${currentStep.step.type}`)
  }
}
